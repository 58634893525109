<template>
  <!--- Campos de busqueda -->
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="KeyName, Nombre, ID Dispositivo" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
       <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
    </el-row>
    <!---- Select Estatus ------->
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="4" :xs="12">
        <el-select placeholder="Estatus" v-on:change="handleSearchFilter" v-model="searchForm.status">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="4" :xs="12">
        <el-select placeholder="Sucursal" v-on:change="handleSearchFilter" v-model="searchForm.bio_location_key">
          <el-option
            v-for="item in biolocationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="16" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <!---- boton export -->
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExportData"
          size="mini"
          type="success"
          icon="el-icon-download"
        >Exportar</el-button>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <!---- Paginacion -->
        <el-pagination style="margin-top: 0.5em;" @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <!---- Tabla de Terminales ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="KeyName" fixed min-width="180" max-width="200" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.key !== undefined ? scope.row.key : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" min-width="180" max-width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name !== undefined ? scope.row.name : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status !== undefined"><el-tag :type="getStatusName(scope.row.status)">{{ scope.row.status ? 'Activo' : 'Inactivo'}}</el-tag></span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ID Dispositivo" min-width="160" max-width="200" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceId !== undefined ? scope.row.deviceId : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Lector de Huella" min-width="140" max-width="160" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.isSensorFingerprint !== undefined"><el-tag type="warning" :effect="scope.row.isSensorFingerprint ? 'light' : 'plain'">{{ getFlag(scope.row.isSensorFingerprint)}}</el-tag></span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sensor de Cámara" min-width="140" max-width="160" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.isSensorCamera !== undefined"><el-tag :effect="scope.row.isSensorCamera ? 'light' : 'plain'">{{ getFlag(scope.row.isSensorCamera)}}</el-tag></span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Notificación de Servidor" width="180" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.isServerNotification !== undefined"><el-tag type="info" :effect="scope.row.isServerNotification ? 'light' : 'plain'">{{ getFlag(scope.row.isServerNotification)}}</el-tag></span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.created_at !==undefined">{{ scope.row.created_at | formatDate }}</span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="140" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.bio_location_key !==undefined ? scope.row.bio_location_key : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Metadata" type="expand" width="110" align="center">
        <template slot-scope="scope">
          <!-- Tabla ID FamilyJR -->
          <el-table stripe :data="scope.row.metadataKeyValue" size="mini" border highlight-current-row>
            <el-table-column label="Propiedad" align="center">
              <template slot-scope="meta">
                <span style="font-weight: 800;">{{ meta.row.keyObj !== undefined ? meta.row.keyObj : 'N/D' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Valor" align="center">
              <template slot-scope="meta">
                <span>{{ meta.row.valueObj !== undefined ? meta.row.valueObj : 'N/D' }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin tabla ID FamilyJR -->
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" width="410" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="Editar Terminal" placement="bottom">
            <el-button @click="handleEditTerminal(scope.row._id)" type="warning" size="mini" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Eliminar Terminal" placement="bottom">
            <el-button @click="handleDeleteTerminal(scope.$index, scope.row)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
            <el-button @click="handleCreateLicense(scope.row)" :disabled="getStatusTypeLicenses(scope.row)" type="success" size="mini">
              <span>Agregar Licencia</span>
              <i class="el-icon-key icon--bold descriptive-icon"></i>
              <i class="el-icon-plus icon--bold"></i>
            </el-button>
            <el-button @click="handleShowLicenses(scope.row.key)" type="primary" size="mini">
              <span>Ver Licencias</span>
              <i class="el-icon-key icon--bold descriptive-icon"></i>
              <i class="el-icon-right icon--bold"></i>
            </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--- Paginacion inferior -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { search as locationSearch } from '@/api/bioLocations.js'
import { search, getPage, deleteTerminal, exportExcel } from '@/api/bioTerminals.js'
const statusColors = {
  true: 'success',
  false: 'danger'
}
export default {
  name: 'bioTerminals',
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: '',
        status: undefined,
        bio_location_key: undefined
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      statusList: [
        {
          value: null,
          label: 'Todo'
        },
        {
          value: true,
          label: 'Activo'
        },
        {
          value: false,
          label: 'Inactivo'
        }],
      biolocationList: [
        {
          value: null,
          label: 'Todas las Sucursales'
        },
        {
          value: 'GRUTA',
          label: 'Gruta'
        }
      ],
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    this.getQuery()
    this.handleSearchFilter()
    this.getLocationSelectOptions()
  },
  methods: {
    getStatusTypeLicenses (row) {
      console.log('changed...')
      return !(row.isSensorFingerprint || row.isSensorCamera)
    },
    async getLocationSelectOptions () {
      try {
        const response = await locationSearch({ query: '' })
        if (response.success) {
          const locationData = await response.data.data
          console.log(response.data.data)
          locationData.forEach(location => {
            this.biolocationList.push({
              label: location.name,
              value: location.key
            })
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    getFlag (prop) {
      return prop ? 'Si' : 'No'
    },
    getStatusName (prop) {
      return statusColors[`${prop}`]
    },
    getQuery () {
      if (this.$route.query !== undefined || this.$route.query !== null) {
        this.searchForm.bio_location_key = this.$route.query.params
      }
      console.log(this.searchForm.bio_location_key)
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        initDate: '',
        endDate: '',
        status: undefined,
        bio_location_key: undefined
      }
      this.handleSearchFilter()
    },
    async handleSearchFilter () {
      this.loading = true
      console.log(this.searchForm.bio_location_key)
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      this.searchForm.paginate = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.metadata) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.metadata[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    handleShowLicenses (terminalKey, locationKey) {
      // TODO: Button to show licenses view
      console.log(locationKey, terminalKey)
      this.$router.push({ name: 'locations.terminals.licenses', query: { bio_location: locationKey, bio_terminal: terminalKey } })
    },
    handleEditTerminal (idTerminal) {
      this.$router.push({
        path: '/terminals/edit',
        query: {
          id: idTerminal
        }
      })
    },
    handleDeleteTerminal (index, row) {
      this.$confirm(`La Terminal ${row.name} y sus Licencias relacionadas serán eliminadas. ¿Desea continuar?`, 'Atención!', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteTerminal(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    handleCreateLicense (row) {
      this.$router.push(
        {
          path: '/licenses/create',
          query: {
            terminal: row.key,
            location: row.bio_location_key,
            idterminal: row._id
          }
        })
    },
    async handleExportData () {
      console.log('antes de export')
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'bio_terminal' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="css">
.icon--bold{
  font-weight: 800;
}
.descriptive-icon{
  margin-left: 0.3em;
  margin-right: 0.3em;
}
</style>
